<template>
  <div class="result">
    <div class="head">
      <img src="https://cxj.szzkba.cn/upload/i/2024/04/22/110359.png" alt="">
    </div>
    <div class="info"><el-divider :style="{ color: '#2D3449', borderColor: '#2D3449', }">保函业务信息</el-divider></div>
    <div class="box">
      <div class="list" v-if="result.bh_code">
        <el-form ref="form" :model="result" label-width="120px" disabled>
          <el-form-item label="被担保人：">
            <el-input type="textarea" v-model="result.bh_user"></el-input>
          </el-form-item>
          <el-form-item label="保函编号：">
            <el-input type="textarea" v-model="result.bh_code"></el-input>
          </el-form-item>
          <el-form-item label="保函受益人：">
            <el-input type="textarea" v-model="result.bh_beft_user"></el-input>
          </el-form-item>
          <el-form-item label="保函金额：">
            <el-input type="textarea" v-model="result.bh_amount"></el-input>
          </el-form-item>
          <el-form-item label="开立日期：">
            <el-input type="textarea" v-model="result.bh_startdate"></el-input>
          </el-form-item>
          <el-form-item label="截止日期：">
            <el-input type="textarea" v-model="result.bh_enddate"></el-input>
          </el-form-item>
          <el-form-item label="保函开立机构：">
            <el-input type="textarea" v-model="result.bh_organization"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div v-else>
        <el-empty description="查无保函"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { search, getList } from './../lib/api/index'
export default {
  name: 'result',
  data() {
    return {
      result: {},
    }
  },
  created() {
    search(this.$route.query.keyword).then(res => {
      console.log(res)
      if (res.code == 0) {
        this.result = res.data.rempBhinfor
        this.result.bh_startdate = this.time_format(this.result.bh_startdate, "yyyy-MM-dd")
        this.result.bh_enddate = this.time_format(this.result.bh_enddate, "yyyy-MM-dd")
      }
    })
  },
  mounted() {
    // console.log(this.time_format('2025-03-24T00:00:00+08:00', "yyyy-MM-dd hh:mm:ss"))
  },
  destroyed() {
  },
  methods: {
    time_format: function (date, fmt) {
      if (!(date instanceof Date)) {
        date = new Date(date);
      }
      var o = {
        "M+": date.getMonth() + 1,                 // 月份
        "d+": date.getDate(),                       // 日
        "h+": date.getHours(),                      // 小时
        "m+": date.getMinutes(),                    // 分钟
        "s+": date.getSeconds(),                    // 秒
        "q+": Math.floor((date.getMonth() + 3) / 3) // 季度
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
      }
      return fmt;
    }
  },
  computed: {

  }
}
</script>

<style lang="less">
.result {
  .head {
    text-align: center;

    img {
      width: 80%;
    }
  }

  .info {
    width: 50%;
    margin: 0 auto 25px;
    white-space: nowrap;
  }

  .box {
    .list {
      width: 94%;
      margin: 0 auto 25px;

      .el-form-item {
        margin-bottom: 0;
      }

      .el-textarea__inner {
        border: none;
        background: transparent;
      }
    }
  }
}
</style>
