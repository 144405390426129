/*
* @About gongw created by 2021/2/1
*/
import request from '@/lib/utils/request'

export function search(bh_code) {
  return request({
    url: '/mpBasefor/findMpBhinforCheck?bh_code=' + bh_code,
    method: 'get',
  })
}
