<template>
  <div id="app">
    <transition :name="direction">
      <router-view/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      direction: 'slide-right'
    }
  },
  created () {
  },
  methods: {},
  watch: {
    $route (to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      if (to.path === '/') {
        this.direction = 'slide-right'
      } else if (from.path === '/') {
        this.direction = 'slide-left'
      } else {
        this.direction = toDepth < fromDepth ? 'slide-right' : 'slide-left'
      }
    }
  }
}
</script>
<style lang="less">
html body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow: hidden;
}

.appView {
  position: absolute;
  width: 100%;
  transition: transform 0.3s ease-out;
}

.slide-left-enter {
  transform: translate(100%, 0);
}

.slide-left-leave-active {
  transform: translate(-50%, 0);
}

.slide-right-enter {
  transform: translate(-50%, 0);
}

.slide-right-leave-active {
  transform: translate(100%, 0);
}
.flex {
  display: flex;
  flex-direction: row;
}

.flex-a {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-b {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-d {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
</style>
