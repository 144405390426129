/*
* @About gongw created by 2021/2/1
*/
import axios from 'axios'
import baseUrl from '@/lib/utils/baseUrl'

const service = axios.create({
  baseURL: baseUrl,
  headers: { 'Access-Control-Allow-Origin': 'origin', },
  timeout: 2000
})

// 添加请求拦截器
service.interceptors.request.use(config => {
  // console.log(config)
  return config
}, (error) => {
  console.log(error)
})

// 添加相应拦截器
service.interceptors.response.use(response => {
  if (response.status === 200) {
    return Promise.resolve(response.data)
  } else {
    return Promise.reject(response)
  }
}, (error) => {
  return Promise.reject(error)
})

// 导出service
export default service
