<template>
  <div class="index">
    <div class="head">
      <img src="https://cxj.szzkba.cn/upload/i/2024/04/22/110359.png" alt="">
    </div>
    <div class="box">
      <el-divider>保函业务查询</el-divider>
      <div class="search">
        <div class="lable"><span>*</span>保函编号</div>
        <el-input v-model="keyword" placeholder="请输入保函编号" />
        <el-button type="primary" @click="jumpTo">立即查询</el-button>
      </div>
    </div>
  </div>
</template>

<script>
// import { search } from './../lib/api/index'
export default {
  name: 'index',
  data() {
    return {
      keyword: '',
    }
  },
  created() {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    jumpTo() {
      this.$router.push({ path: '/result', query: { keyword: this.keyword } })
    }
  },
  computed: {

  }
}
</script>

<style lang="less">
.index {
  .head {
    text-align: center;

    img {
      width: 80%;
    }
  }

  .box {
    margin: 0 5%;

    .lable {
      span {
        color: red;
        margin-right: 5px;
      }

      color: #5D5B5E;
      font-size: 16px;
      margin-bottom: 5px;
    }

    .el-button {
      width: 100%;
      margin-top: 40px;
    }
  }
}
</style>
