/*
* @About gongw created by 2021/2/23
*/
let baseUrl = ''
if (process.env.NODE_ENV === 'production') {
  baseUrl = 'http://yhx.szzkba.cn/api/'
  // baseUrl = 'https://www.jingweicd.com/api'
} else {
  baseUrl = 'http://yhx.szzkba.cn/api/'
  // baseUrl = 'https://www.jingweicd.com/api'
}
export default baseUrl
